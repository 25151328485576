import React, { useCallback, useContext } from 'react'
import { getFirebaseAuth } from '@babelcoder/gatsby-plugin-firebase'
import { navigate } from 'gatsby'

import { GlobalStateContext } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer'
import AuthGuard, {
  TYPES,
} from '@babelcoder/gatsby-theme-base/src/components/AuthGuard'
import AuthForm from '@components/auth/AuthForm'
import authErrors from '@utils/auth-errors'

function SignInPage({ location: { state } }) {
  const { dispatch } = useContext(GlobalStateContext)

  const signin = useCallback(
    async ({ email, password }) => {
      try {
        const firebaseAuth = await getFirebaseAuth()

        await firebaseAuth.signInWithEmailAndPassword(email, password)
        navigate(state?.prevPath ? state.prevPath : '/', {
          replace: true,
        })
      } catch (error) {
        let message =
          authErrors[error.code] ||
          'เกิดข้อผิดพลาดในการเข้าสู่ระบบ โปรดลองอีกครั้ง'

        dispatch({
          type: actions.showPopupMessage,
          popupMessage: {
            type: 'danger',
            title: 'เกิดข้อผิดพลาด',
            message,
          },
        })
      }
    },
    [dispatch, state]
  )

  return (
    <AuthGuard type={TYPES.AUTH_DENY}>
      <AuthForm
        type="SIGN_IN"
        onSubmit={signin}
        title="สวัสดี, ยินดีที่ได้พบอีกครั้ง"
        submitText="เข้าสู่ระบบ"
      ></AuthForm>
    </AuthGuard>
  )
}

export default SignInPage
